import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "create-a-destination",
      "style": {
        "position": "relative"
      }
    }}>{`Create a Destination`}<a parentName="h2" {...{
        "href": "#create-a-destination",
        "aria-label": "create a destination permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may create an unlimited number of Destinations of any type. Creating a Destination starts with choosing a type and
setting a name. Configuring a Destination depends on the Destination type's available options.`}</p>
    <p>{`Before setting up a Destination, ensure you have a Connection configured for that Destination's type.`}</p>
    <p><strong parentName="p">{`To create a new Destination:`}</strong></p>
    <ol>
      <li parentName="ol">{`Navigate to the Data & Analytics Application`}</li>
      <li parentName="ol">{`Select Destinations from the navigation menu`}</li>
      <li parentName="ol">{`Click the (+) icon in the bottom corner`}</li>
      <li parentName="ol">{`Select the type of Destination you'd like to configure.`}</li>
      <li parentName="ol">{`Provide a name for the Destination and select a Connection`}</li>
      <li parentName="ol">{`Click "Create Destination"`}</li>
    </ol>
    <h2 {...{
      "id": "update-a-destination",
      "style": {
        "position": "relative"
      }
    }}>{`Update a Destination`}<a parentName="h2" {...{
        "href": "#update-a-destination",
        "aria-label": "update a destination permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You may update a Destination at any time. A Destination's available options are dependent on the Destination type.`}</p>
    <p><strong parentName="p">{`To update a Destination:`}</strong></p>
    <ol>
      <li parentName="ol">{`Navigate to the Data Destinations and Destinations Application`}</li>
      <li parentName="ol">{`Select Destinations from the navigation menu`}</li>
      <li parentName="ol">{`Select the name of the Destination you'd like to update`}</li>
      <li parentName="ol">{`Select the Settings tab`}</li>
      <li parentName="ol">{`Modify the fields of your choice and click Save Destination to finish
.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      